import { ReactNode } from "react";
import { ConfigureDispositionCollapsibleContext } from "@/components/orders/disposition/DispositionHeader/ConfigureDispositionModal/ConfigureDispositionCollapsible/ConfigureDispositionCollapsibleContext";

type ConfigureDispositionCollapsibleRootProps = {
    children: ReactNode;

    open: boolean;
    onOpenChange: (open: boolean) => void;
};

export function ConfigureDispositionCollapsibleRoot(props: ConfigureDispositionCollapsibleRootProps) {
    return (
        <ConfigureDispositionCollapsibleContext.Provider value={{ open: props.open, setOpen: props.onOpenChange }}>
            <div className="configure-disposition-collapsible-root">{props.children}</div>
        </ConfigureDispositionCollapsibleContext.Provider>
    );
}
