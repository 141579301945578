import { IonCheckbox } from "@ionic/react";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import "./configureDispositionCollapsibleItem.scss";

type ConfigureDispositionCollapsibleItemProps = ComponentPropsWithoutRef<"div"> & {
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
};

export function ConfigureDispositionCollapsibleItem(props: ConfigureDispositionCollapsibleItemProps) {
    const { checked, onCheckedChange, ...restProps } = props;

    return (
        <div
            className={clsx("configure-disposition-collapsible-item", {
                "configure-disposition-collapsible-item--checked": checked,
            })}
            onClick={() => {
                onCheckedChange(!checked);
            }}>
            <IonCheckbox
                checked={checked}
                onClick={event => {
                    event.preventDefault();
                }}
            />
            <div
                {...restProps}
                className={clsx("configure-disposition-collapsible-item__content", restProps.className)}
            />
        </div>
    );
}
