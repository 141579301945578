import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ReactNode } from "react";
import { useConfigureDispositionCollapsibleContext } from "@/components/orders/disposition/DispositionHeader/ConfigureDispositionModal/ConfigureDispositionCollapsible/ConfigureDispositionCollapsibleContext";
import "./configureDispositionCollapsibleHeader.scss";

type ConfigureDispositionCollapsibleHeaderProps = {
    title: ReactNode;
    selection: ReactNode;
};

export function ConfigureDispositionCollapsibleHeader(props: ConfigureDispositionCollapsibleHeaderProps) {
    const { open, setOpen } = useConfigureDispositionCollapsibleContext();

    return (
        <div
            className={clsx("configure-disposition-collapsible-header", {
                "configure-disposition-collapsible-header--open": open,
            })}
            onClick={() => {
                setOpen(!open);
            }}>
            <h4 className="configure-disposition-collapsible-header__title">{props.title}</h4>

            <span className="configure-disposition-collapsible-header__selection">{props.selection}</span>
            <FontAwesomeIcon icon={faChevronRight} className="configure-disposition-collapsible-header__icon" />
        </div>
    );
}
